@font-face {
  font-family: pacifico;
  src: url('fonts/Pacifico.ttf');
}

@font-face {
  font-family: crosshatcher;
  src: url('fonts/space.ttf');
}

@font-face {
  font-family: source code pro;
  src: url('fonts/SourceCodePro.otf');
}


 .iframe {
      width: 100%;
      height: 100%;
    }


.browserPadding{
  padding-left: 24px !important;
}
 ul{
  list-style: none !important;
 }

 .full-width{
  width:100% !important;
 }

.full-height{
  height:100vh;
}


.teamsbg{
  background-color: #474476 ;
}

.codeSectionThin{
position: relative;
left: 40%;
}

.top-clear{
  margin-top:3%
}

#playgroundLab{
  background-color: #000000
}

#covid-flag{
 background-image: url('images/covid-bg.jpg');
 background-size:cover;
}

#createProject{
   border-right: 1px solid white !important
}

.frame-position{
    margin-top: 20%;
}

 .padding-off{
   padding: 0; 
 }

 .margin-off{
   margin: 0;
}

.ml-off{
  margin-left:0px !important;
}

 .header-font{
    font-family: crosshatcher;
 }

 .body-font{
     font-family: source code pro;
 }

 .sketch-font{
   font-family: KG Blank Space Sketch;
 }

.circular{
  border-radius: 50%;
}

.margin-bt{
  margin-bottom: 3%
}
 
.renew{
  cursor: pointer;
}

.pgEditor{
 height: 50vh;
}

.taskButton{
  color: grey !important;
  border: 1px solid grey;
}

.taskButton:hover{ 
  border: 1px solid grey;
}

.azure-bg{
  background-color: azure;
  border: transparent;
}

.renew:hover{
   color: #868383
}

 #labBoxStyle{
  height:84.7vh;
  overflow-y: scroll;
  overflow-x: hidden;
 }

#labBoxStyleClass{
  height:84.7vh;
  overflow-y: hidden;
  overflow-x: hidden;
 }

 #labBoxStyleClass::-webkit-scrollbar{ 
  display: none !important
 }
 
 #labBoxStyle::-webkit-scrollbar{ 
  display: none !important
 }

.sub-menu-layout::-webkit-scrollbar { 
  display: none !important
}

.form-check-input{
  margin-top: 1.3rem !important;
}

#sleep-screen{
  width:100%;
  height: 85vh;
  display:none;
  margin-bottom: 4.4%
}

.awssld__content{
   background-color: white !important
}

#MyLiveChatScriptButton{
    position: absolute !important;
    left: -35% !important;
    top: 5% !important;
    width:100px;
}

#load-screen{
  width:100%;
  height: 85vh;
  display:none;
  margin-bottom: 4.4%;
  z-index:100;
  position: relative;
}

#horizontal-screen{
  width:100%;
  height: 85vh;
  display:none;
  margin-bottom: 4.4%;
  z-index:100;
  position: relative;
}

#menu-screen{
  width:100%;
  height: 85vh;
  display:none;
  margin-bottom: 4.4%
}

.chapterSelection{
   cursor: pointer;
   color:white
}

.chapterSelection:hover{
  color:white
}

.settings{
  background-color: #232222;
  position: absolute;
  z-index: 50;
  left: 26%;
  top: 25%;
  opacity: 0.9;
  display: none;
}

.themeHeight{
  height:100px
}

.themeHeight1{
 background: linear-gradient(0deg, rgba(23, 144, 247,1), rgba(23, 144, 247, 1))
}

.themeHeight2{
  background: linear-gradient(0deg, rgba(89, 68, 0, 1), rgba(89, 68, 0, 1))
 }

 .themeHeight3{
  background: linear-gradient(0deg, rgb(12, 34, 23, 1), rgba(12, 34,23, 1))
 }

 .themeHeight4{
  background: linear-gradient(0deg, rgb(250, 0, 150, 1), rgba(250, 0, 150, 1))
 }

 .border-control{
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
 }

 .portfolio-selection{
  position: fixed;
  left: 5.5%;
  top: 8.45%;
 }
 
#topic-screen{
  width:100%;
  height: 85vh;
  display:none;
  margin-bottom: 4.4%
}

#book-screen{
  width:100%;
  height: 85vh;
  display:none;
  margin-bottom: 4.4%
}

#fellows-screen{
  width:100%;
  height: 85vh;
  display:none;
  margin-bottom: 4.4%
}


#explore-screen{
  width:100%;
  height: 85vh;
  display:none;
  margin-bottom: 4.4%
}


#result-screen{
  width:100%;
  height: 85vh;
  display:none;
  margin-bottom: 4.4%
}
.sub-menu-layout{ 
   overflow-y: scroll;
}

.accent-font{ 
     font-family: pacifico;
  }

 .hidden{
     display: none;
 }

 #lab-bg{
   background-image: url('images/covid-bg.jpg');
 background-size:cover;
 }

  .height-test-minus{ 
      height: 64vh;
  }


  .tiny-font{ 
     font-size: 10px;
  }

  .blue-text{ 
    color: #02b9fd;
   }

   .white-text{ 
     color: #ffffff !important;
  }

  .red-text{
    color: #dd4646;
  }

  .column-margin{
    margin-bottom:10% !important;
  }
  
   .grey-text{
    color: #868383
   }
 
  .yellow-text{
     color: #fdf72d; 
  }

 .black-background{
    background-color: black;
 }

  .black-background-trans{
    background-color: rgba(0,0,0,0.7);
 }

.canvasHeightThin{
      height: 83vh
}

#codeMirrorThinStart{
  position: relative;
    top: 25%;
}

#codeMirrorThin{
   height:20vh;
}

#explanation{
  height: 85vh;
  overflow:scroll
}

.CodeMirror-scroll{
  overflow: scroll !important;
  height: 48vh !important;
}

 .white-background{ 
     background-color: #ffffff !important;
     border:transparent;
  }

  .black-background{ 
    background-color: #000000 !important;
    border:transparent;
 }

   .white-background-trans{ 
     background-color: rgba(255, 255, 255, 0.6) !important;
  }

 .blue-background{ 
    background-color: #02b9fd;
   }

 .blue-background-2{
    background-color: #117ecb;
 }

.blue-background-light{ 
    background-color: rgba(8, 185, 253, 0.3);
   }

.green-background{ 
   background-color: #4d950c;
 }

 .tabs-btn-hover{
    color: #4d950c;
 }

  .tabs-btn-hover:hover{
    color: #02b9fd;
 }

 .green-gradient-bg{
    background-color:rgb(104,154,54);
 }

.green-background-trans{ 
   background-color: rgba(77,149,12, 0.9);
 }

 .yellow-background{ 
   background-color: #fdf72d ;
  }

  .red-background{ 
   background-color:red ;
  }

 .standard-box-curve { 
     border-radius: 5%;
  }

  .rounded-image{
    border-radius: 50%;
  }

  .top-left-curve{ 
    border-top-left-radius: 25px;
  }

   .top-right-curve{ 
    border-top-right-radius: 10px;
  }

   .bottom-right-curve{ 
    border-bottom-right-radius: 10px;
  }

  .bottom-left-curve{ 
    border-bottom-left-radius: 25px;
   }

   .left-side-curve{ 
   border-top-left-radius: 35px; 
   border-bottom-left-radius: 35px;
   }

  .standard-round-box-curve { 
     border-radius: 25px;
     border: transparent;
  }

  .standard-round-box-curve-2 { 
     border-radius: 50px;
     border: transparent;
  }

 .standard-padding-tb{ 
      padding-top: 4%;
      padding-bottom: 4%;
  }

  .standard-padding-tb-peditor{ 
    padding-top: 11%;
    padding-bottom: 11%;
}

   .standard-padding-tb-2{ 
      padding-top: 1%;
      padding-bottom: 1%;
  }

  .standard-padding-top-1{ 
      padding-top: 1%;
  }
 


     .standard-padding-tb-3{ 
      padding-top: 2.5%;
      padding-bottom: 2.5%;
  }

 .standard-padding-top{ 
      padding-top: 4%;
  }

   .standard-margin-top-covid{ 
      padding-top: 20%;
  }


  .standard-padding-rl{
    padding-right: 3% ;
    padding-left: 3% ;
  }

.standard-margin-top-quiz{
     margin-top: 2%;
}

.standard-margin-top{ 
    margin-top: 4%;
}

.clear-space{
  margin-top:10%;
}

.standard-padding-tb-skin{
    margin-top: 1.8%;
    margin-bottom:2.5%;
}

.standard-padding-tb-skin-visual{
   
 
}


.modal-backdrop{
  background-color: transparent
}


#covid-modal{
  margin-top:-10% !important;
}

.modal-dialog{
  margin-top: 9%;
  background-color: rgba(288, 288, 288, 0.8);
}

.modal-content{
  background-color: transparent;
}

#covid-modal-body{

}

.app-screen-margin{
  margin-right: 5%;
  margin-left:5%;
}

.app-screen-margin-1{
  margin-right: 3%;
  margin-left:3%;
}

.app-screen-padding{
  padding-right: 2%;
  padding-left: 1%
}

.app-screen-padding-1{
  padding-right: 4%;
  padding-left: 5%
}

#sidebar-properties{
  height:100%;
}

#editor-properties{
height: 85vh;
}

#editor-instructions{
  height: 34vh;
}

.CodeMirror{
  height:inherit    !important;
}

.CodeMirror2{
  height:54.3vh !important;
}

.portfolioCase{
  display: none 
}

.container-pad{
  padding-right: 19px;
}

.IWH{
     background-size: cover;
}

.task-steps-box{
  height: 80px;
  overflow-y: scroll;
}

.task-steps-box::-webkit-scrollbar {
    width: 1em;
    background-color: red !important
}

.task-steps-box::-webkit-scrollbar-thumb {
    width: 1em;
    background-color: slategrey !important
}
/** test styles for skin **/

.marginLeft{
  margin-left:2%;
}

.gmates{
  top: 27%;
    left: 10%;
    position: relative;
}

.editorCase{
    display: block;
}

 .skin-actual{
    position: absolute;
    top:0.5%;
    z-index: 3;
    height:100vh
}

.nav-clear-space{
    padding-top:7%;
     background-image: url('images/3.jpg');
     background-size:cover;
}

.nav-clear-space-screen{
  margin-top: 4.4%;
}

.classEditor{
  height: 40vh
}

.skin{
  position:relative;
  z-index:4;
}

.pos-control{
  z-index: 2 !important;
}

  .navbarStyle{ 
    box-shadow: #808080 1px 1px 6px
  }

  .sub-menu-style{ 
    box-shadow: #000000 0px 2px 1px 0px;
   }

  .input-field-width{ 
       width:70%;
       border: 1px solid grey !important
   }


.white-border{
  border-color:white !important;
}
/** code editor styles **/

.code-editor{
  color: red !important;
}

@media(max-width:1678px){ 


.nav-clear-space-screen {
    margin-top: 4.2% !important;
}

}


@media(max-width:1604px){ 


  .nav-clear-space-screen {
      margin-top: 4% !important;
  }
  
  }


  @media(max-width:1350px){ 

    #labBoxStyleClass {
      height: 85vh !important;
  }
    #labBoxStyle {
        height: 85vh !important;
    }
    
    }

    @media(max-width:1250px){ 

      #labBoxStyleClass {
        height: 85vh !important;
    }
      #labBoxStyle {
          height: 85vh !important;
      }
      
      }

      
    @media(max-width:1150px){ 

      #labBoxStyleClass {
        height: 85vh !important;
    }
      #labBoxStyle {
          height: 85vh !important;
      }
      
      }


@media(max-width: 991px){


  .standard-margin-top-quiz {
    margin-top: 0;
} 

.standard-padding-tb-3 {
  padding-top: 1.0%;
  padding-bottom: 1.0%;
}
  #labBoxStyleClass {
    height: 85vh !important;
}

#labBoxStyle {
  height: 85vh !important;
}

   .standard-margin-top-covid{ 
      padding-top: 55%;
  }

  .clear-space{
    margin-top:20%;
  }

  .mobile-text{
    font-size:10px;
  }

  .tiny-font{
    font-size: 6px;
  }

  .tabs-btn-hover{
    font-size: 0.7rem;
  }

  .mobile-text-size{
    font-size: 0.6em
  }

  

  #MyLiveChatScriptButton {
    position: absolute!important;
    left: -51%!important;
    top: 49%!important;
}
}

@media(max-width:900px){ 


  #labBoxStyleClass {
      height: 87vh !important;
  }


    

  
  }


  @media(max-width:800px){ 

    
    }
/**
@media(max-height:800px){

  #labBoxStyle {
    height: 87.1vh;
}

.CodeMirror2 {
    height: 53.2vh!important;
}

.height-test-minus {
    height: 73vh;
}

}

**/




@media(max-height: 800px ){


  .standard-margin-top-quiz {
    margin-top: 0;
} 

#labBoxStyleClass {
  height: 86.5vh !important;
}

#labBoxStyle{
  height: 86.5vh !important;
}

#explanation {
  height: 86.5vh;
}

#editor-properties {
  height: 86.5vh;
}

.height-test-minus {
  height: 69vh;
}

}

@media(max-height: 768px ){

  .standard-margin-top-quiz {
    margin-top: 0;
} 

#labBoxStyleClass {
  height: 87.7vh !important;
}

#labBoxStyle{
  height: 87.7vh !important;
}

#explanation {
  height: 87.7vh;
}

#editor-properties {
  height: 87.7vh;
}

.height-test-minus {
  height: 67vh;
}

}

@media(max-height: 600px ){

  .standard-margin-top-quiz {
    margin-top: 0;
} 

.standard-padding-tb-3 {
  padding-top: 1.0%;
  padding-bottom: 1.0%;
}
  #labBoxStyleClass {
    height: 86vh !important;
}

#labBoxStyle {
  height: 87vh !important;
}

.height-test-minus {
  height: 67vh;
}

#editor-properties {
  height: 86vh;
}

.tabs-btn-hover{
  font-size: 0.7rem;
}

.task-steps-box {
  height: 143px;
}

#editor-instructions {
  height: 40vh;
}

.CodeMirror2{
 height: 54.3vh !important
}

.mobile-text-size{
  font-size: 0.8em
}

  .nav-clear-space-screen{
  margin-top: 4.0%;
}
}

@media(max-height:534px){

 .height-test-minus{
    height: 64vh;
  }
#editor-instructions {
    height: 37vh;
}

.CodeMirror2 {
    height: 50.2vh!important;
}

}

@media(max-height:477px){ 

     .navbar-skin{
      top:91%;
     }

     #editor-instructions {
    height: 39vh;
}

#labBoxStyleClass {
    height: 86.1vh;
  
}


#labBoxStyle {
  height: 86.1vh;
  overflow-y: scroll;
}

}

@media(max-height:375px){

  .height-test-minus{
     height: 59vh;
   }
 
   #editor-properties {
     height: 87vh;
 }
 
 #editor-instructions {
     height: 43vh;
 }
 
 .CodeMirror2 {
     height: 50.2vh!important;
 }
}
